<template>
	<w-section no-margin :title="$t('bill-scrapper.automations.title')">
		<ErrorDialog />
		<w-layout align-center justify-center pa-3 row wrap>
			<w-text-info :text="$t('bill-scrapper.automations.add_connector_info')" />
			<w-spacer />
			<w-btn icon="add" @click="showForm = true">{{ $t('bill-scrapper.automations.add_connector') }}</w-btn>
		</w-layout>
		<w-layout row>
			<w-flex xs11>
				<w-layout column>
					<PendingConnectionWebview />
					<ConnectionForm v-show="showForm" />
					<w-flex>
						<PendingConnectionsList />
						<ConnectionsList />
					</w-flex>
				</w-layout>
			</w-flex>
		</w-layout>
	</w-section>
</template>

<script>
import BillScrapperModuleGuard from '@/mixins/ModulesGuards/BillScrapper/BillScrapperModuleGuard'

export default {
	name: 'BillScrapperAutomationsContainer',
	components: {
		ConnectionsList: () => ({
			component: import('@/components/BillScrapper/Automations/ConnectionsList')
		}),
		ConnectionForm: () => ({
			component: import('@/components/BillScrapper/Automations/ConnectionForm')
		}),
		ErrorDialog: () => ({
			component: import('@/components/BillScrapper/Automations/ErrorDialog')
		}),
		PendingConnectionsList: () => ({
			component: import('@/components/BillScrapper/Automations/PendingConnectionsList')
		}),
		PendingConnectionWebview: () => ({
			component: import('@/components/BillScrapper/Automations/PendingConnectionWebview')
		})
	},
	mixins: [BillScrapperModuleGuard],
	data: function () {
		return {
			showForm: false
		}
	},
	methods: {
		getModuleEventsActionsMapping: function () {
			return [
				{ event: this.events.CLOSE_FORM, action: this.closeForm },
				{ event: this.events.UPDATE_CONNECTION, action: this.openForm }
			]
		},
		closeForm: function () {
			this.showForm = false
		},
		openForm: function () {
			this.$el.scrollIntoView({
				behavior: 'smooth'
			})
			this.showForm = true
		}
	}
}
</script>
