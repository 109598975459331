import { Config } from '@/services/ConfigService'
import { Configurator } from '@/plugins/axios/Configurator'

const axios = Configurator.defaultConfiguration(Config.VUE_APP_ROOT_API + '/api/bill-scrapper/')

const createConnection = (accountingFirmId, vendorId, data, config = {}) => {
	const url = `${accountingFirmId}/${vendorId}/connections`
	return axios.post(url, data, config)
}

const deleteConnection = (accountingFirmId, vendorId, connectionId) => {
	const url = `${accountingFirmId}/${vendorId}/connections/${connectionId}`
	return axios.delete(url)
}

const getConnections = (accountingFirmId, vendorId) => {
	const url = `${accountingFirmId}/${vendorId}/connections`
	return axios.get(url)
}

const synchronizeConnection = (accountingFirmId, vendorId, connectionId) => {
	const url = `${accountingFirmId}/${vendorId}/connections/${connectionId}/synchronize`
	return axios.post(url)
}

const updateConnection = (accountingFirmId, vendorId, connectionId, data, config = {}) => {
	const url = `${accountingFirmId}/${vendorId}/connections/${connectionId}`
	return axios.patch(url, data, config)
}

const getProviders = (accountingFirmId, vendorId) => {
	const url = `${accountingFirmId}/${vendorId}/providers`
	return axios.get(url)
}

const getPendingConnections = (accountingFirmId, companyId) => {
	const url = `${accountingFirmId}/${companyId}/pending-connections`
	return axios.get(url)
}

const getPendingConnection = (accountingFirmId, companyId, connectionId) => {
	const url = `${accountingFirmId}/${companyId}/pending-connections/${connectionId}`
	return axios.get(url)
}

const cancelPendingConnection = (accountingFirmId, companyId, connectionId) => {
	const url = `${accountingFirmId}/${companyId}/pending-connections/${connectionId}`
	return axios.delete(url)
}

export default {
	createConnection: createConnection,
	deleteConnection: deleteConnection,
	getConnections: getConnections,
	synchronizeConnection: synchronizeConnection,
	updateConnection: updateConnection,
	getProviders: getProviders,
	getPendingConnections: getPendingConnections,
	getPendingConnection: getPendingConnection,
	cancelPendingConnection: cancelPendingConnection
}
