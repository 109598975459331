import { EventBus } from '@/events/EventBus'

export const Bus = new EventBus()

export const Events = Object.freeze({
	CLOSE_FORM: 'close-form',
	PENDING_CONNECTION_CREATED: 'pending-connection-created',
	PENDING_CONNECTION_CANCELED: 'pending-connection-canceled',
	PENDING_CONNECTION_UPDATED: 'pending-connection-updated',
	CANCEL_PENDING_CONNECTION_WEBVIEW: 'cancel-pending-connection',
	SHOW_PENDING_CONNECTION_WEBVIEW: 'show-pending-connection-webview',
	CLOSE_PENDING_CONNECTION_WEBVIEW: 'close-pending-connection-webview',
	UPDATE_PENDING_CONNECTION: 'update-pending-connection',
	CONNECTION_CREATED: 'connection-created',
	CONNECTION_DELETED: 'connection-deleted',
	CONNECTION_UPDATED: 'connection-updated',
	SHOW_ERROR: 'show-error',
	UPDATE_CONNECTION: 'update-connection'
})
