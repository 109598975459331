// ============================================================================
// BillScrapperService
// -------------------
// BillScrapper module related services
// ============================================================================

// -------
// Imports
// -------
import { store } from '@/store'

import API from '@/apis/BillScrapperApi'
import { Bus as ModuleEventBus, Events as EVENTS } from '@/events/BillScrapper/BillScrapperEvents'

import { Bus as AppEventBus, Events as AppEvents } from '@/events/AppEvents'

import DefaultInterceptor from '@/plugins/axios/DefaultInterceptor'

// ---------
// Internals
// ---------
const Private = {
	showSuccess: function (message) {
		AppEventBus.emit(AppEvents.SNACKBAR_SUCCESS, message)
	},
	showConnectionSuccess: function () {
		Private.showSuccess(window.vueInstance.$t('bill-scrapper.automations.connection_created'))
	},
	showSuccessResponse: function (response) {
		let result = null
		if (response?.is_resolved) {
			ModuleEventBus.emit(EVENTS.PENDING_CONNECTION_UPDATED, response.id)
			ModuleEventBus.emit(EVENTS.CONNECTION_CREATED, response.connection)
			Private.showConnectionSuccess()
			result = response
		}
		return result
	},
	ADDITIONAL_INFORMATION_NEEDED: 'additional-information-needed'
}

// -------
// Exports
// -------
export default {
	activateConnection: function (connectionId) {
		const accountingFirmId = store.state.accountingFirm?.selected?.id
		const vendorId = store.state.company?.selected?.id
		const data = {
			is_active: true
		}
		return API.updateConnection(accountingFirmId, vendorId, connectionId, data)
			.then(res => res.data.data)
			.then(connection => {
				ModuleEventBus.emit(EVENTS.CONNECTION_UPDATED, connection)
				AppEventBus.emit(AppEvents.SNACKBAR_SUCCESS, window.vueInstance.$t('bill-scrapper.automations.connection_activated'))
				return connection
			})
			.catch(err => {
				if (!err?.response?.data?.error) {
					if (err?.response?.config) {
						err.response.config.show_error = true
					}
					return DefaultInterceptor.onResponseError(err)
				}
				const errorCode = err.response.data.error.code
				const errorMessage = err.response.data.error.message
				const status = err.response.status
				if (status === 400 && errorCode === Private.ADDITIONAL_INFORMATION_NEEDED) {
					const connection = err.response.data.error.connection
					ModuleEventBus.emit(EVENTS.CONNECTION_UPDATED, connection)
					AppEventBus.emit(AppEvents.SNACKBAR_SUCCESS, window.vueInstance.$t('bill-scrapper.automations.connection_activated'))
					return connection
				}
				ModuleEventBus.emit(EVENTS.SHOW_ERROR, errorMessage)
				return Promise.reject(err)
			})
	},
	createConnection: function (accountingFirmId, vendorId, providerId, label, connectionData) {
		const data = {
			connection_data: connectionData,
			provider_id: providerId
		}
		if (label) {
			data.label = label
		}
		return API.createConnection(accountingFirmId, vendorId, data, { show_error: false })
			.then(response => response.data.data)
			.then(connection => {
				ModuleEventBus.emit(EVENTS.CLOSE_FORM)
				switch (connection.type) {
					case 'connection':
						ModuleEventBus.emit(EVENTS.CONNECTION_CREATED, connection)
						Private.showConnectionSuccess()
						break
					case 'pending-connection':
						ModuleEventBus.emit(EVENTS.PENDING_CONNECTION_CREATED, connection)
						ModuleEventBus.emit(EVENTS.SHOW_PENDING_CONNECTION_WEBVIEW, connection)
						Private.showSuccess(window.vueInstance.$t('bill-scrapper.automations.pending_connection_created'))
						break
				}
				return connection
			})
			.catch(err => {
				if (!err?.response?.data?.error) {
					if (err?.response?.config) {
						err.response.config.show_error = true
					}
					return DefaultInterceptor.onResponseError(err)
				}
				const errorCode = err.response.data.error.code
				const errorMessage = err.response.data.error.message
				const status = err.response.status
				if (status === 400 && errorCode === Private.ADDITIONAL_INFORMATION_NEEDED) {
					const connection = err.response.data.error.connection
					ModuleEventBus.emit(EVENTS.CONNECTION_CREATED, connection)
					AppEventBus.emit(AppEvents.SNACKBAR_WARNING, errorMessage)
					return Promise.reject({
						code: errorCode,
						connection: connection,
						fields: err.response.data.error.fields
					})
				}
				ModuleEventBus.emit(EVENTS.SHOW_ERROR, errorMessage)
				return Promise.reject(err)
			})
	},
	deactivateConnection: function (connectionId) {
		const accountingFirmId = store.state.accountingFirm?.selected?.id
		const vendorId = store.state.company?.selected?.id
		const data = {
			is_active: false
		}
		return API.updateConnection(accountingFirmId, vendorId, connectionId, data)
			.then(res => res.data.data)
			.then(connection => {
				ModuleEventBus.emit(EVENTS.CONNECTION_UPDATED, connection)
				AppEventBus.emit(AppEvents.SNACKBAR_SUCCESS, window.vueInstance.$t('bill-scrapper.automations.connection_deactivated'))
				return connection
			})
			.catch(err => {
				if (!err?.response?.data?.error) {
					if (err?.response?.config) {
						err.response.config.show_error = true
					}
					return DefaultInterceptor.onResponseError(err)
				}
				const errorCode = err.response.data.error.code
				const errorMessage = err.response.data.error.message
				const status = err.response.status
				if (status === 400 && errorCode === Private.ADDITIONAL_INFORMATION_NEEDED) {
					const connection = err.response.data.error.connection
					ModuleEventBus.emit(EVENTS.CONNECTION_UPDATE, connection)
					AppEventBus.emit(AppEvents.SNACKBAR_SUCCESS, window.vueInstance.$t('bill-scrapper.automations.connection_deactivated'))
					return connection
				}
				ModuleEventBus.emit(EVENTS.SHOW_ERROR, errorMessage)
				return Promise.reject(err)
			})
	},
	deleteConnection: function (connectionId) {
		const accountingFirmId = store.state.accountingFirm?.selected?.id
		const vendorId = store.state.company?.selected?.id
		return API.deleteConnection(accountingFirmId, vendorId, connectionId).then(() => {
			ModuleEventBus.emit(EVENTS.CONNECTION_DELETED, connectionId)
			AppEventBus.emit(AppEvents.SNACKBAR_SUCCESS, window.vueInstance.$t('bill-scrapper.automations.connection_deleted'))
			return connectionId
		})
	},
	cancelPendingConnection: function (connectionId) {
		const accountingFirmId = store.state.accountingFirm?.selected?.id
		const vendorId = store.state.company?.selected?.id
		return API.cancelPendingConnection(accountingFirmId, vendorId, connectionId).then(() => {
			ModuleEventBus.emit(EVENTS.PENDING_CONNECTION_CANCELED, connectionId)
			Private.showSuccess(window.vueInstance.$t('bill-scrapper.automations.connection_deleted'))
			return connectionId
		})
	},
	getConnections: function () {
		const accountingFirmId = store.state.accountingFirm?.selected?.id
		const vendorId = store.state.company?.selected?.id
		return API.getConnections(accountingFirmId, vendorId).then(res => res.data.data)
	},
	getPendingConnections: function () {
		const accountingFirmId = store.state.accountingFirm?.selected?.id
		const vendorId = store.state.company?.selected?.id
		return API.getPendingConnections(accountingFirmId, vendorId).then(res => res.data.data)
	},
	getPendingConnection: function (pendingConnectionId) {
		const accountingFirmId = store.state.accountingFirm?.selected?.id
		const vendorId = store.state.company?.selected?.id
		return API.getPendingConnection(accountingFirmId, vendorId, pendingConnectionId).then(res => res.data.data)
	},
	synchronizeConnection: function (connectionId) {
		const accountingFirmId = store.state.accountingFirm?.selected?.id
		const vendorId = store.state.company?.selected?.id
		return API.synchronizeConnection(accountingFirmId, vendorId, connectionId)
			.then(res => res.data.data)
			.then(connection => {
				ModuleEventBus.emit(EVENTS.CONNECTION_UPDATED, connection)
				AppEventBus.emit(AppEvents.SNACKBAR_SUCCESS, window.vueInstance.$t('bill-scrapper.automations.synchronization_launched'))
				return connection
			})
	},
	updateConnection: function (accountingFirmId, vendorId, connectionId, data) {
		return API.updateConnection(accountingFirmId, vendorId, connectionId, data, { show_error: false })
			.then(response => response.data.data)
			.then(connection => {
				ModuleEventBus.emit(EVENTS.CLOSE_FORM)
				ModuleEventBus.emit(EVENTS.CONNECTION_UPDATED, connection)
				AppEventBus.emit(AppEvents.SNACKBAR_SUCCESS, window.vueInstance.$t('bill-scrapper.automations.connection_updated'))
				return connection
			})
			.catch(err => {
				if (!err?.response?.data?.error) {
					if (err?.response?.config) {
						err.response.config.show_error = true
					}
					return DefaultInterceptor.onResponseError(err)
				}
				const errorCode = err.response.data.error.code
				const errorMessage = err.response.data.error.message
				const status = err.response.status
				if (status === 400 && errorCode === Private.ADDITIONAL_INFORMATION_NEEDED) {
					const connection = err.response.data.error.connection
					ModuleEventBus.emit(EVENTS.CONNECTION_UPDATED, connection)
					AppEventBus.emit(AppEvents.SNACKBAR_WARNING, errorMessage)
					return Promise.reject({
						code: errorCode,
						connection: connection,
						fields: err.response.data.error.fields
					})
				}
				ModuleEventBus.emit(EVENTS.SHOW_ERROR, errorMessage)
				return Promise.reject(err)
			})
	},
	updatePendingConnection: function (pendingConnectionId, force = false) {
		const accountingFirmId = store.state.accountingFirm?.selected?.id
		const vendorId = store.state.company?.selected?.id
		let result = API.getPendingConnection(accountingFirmId, vendorId, pendingConnectionId).then(response => Private.showSuccessResponse(response))
		if (force) {
			result = result
				.then(response => {
					if (response == null) {
						return API.getPendingConnection(accountingFirmId, vendorId, pendingConnectionId).then(response => Private.showSuccessResponse(response))
					}
					return response
				})
		}
		return result
	},
	getProviders: function () {
		const accountingFirmId = store.state.accountingFirm?.selected?.id
		const vendorId = store.state.company?.selected?.id
		return API.getProviders(accountingFirmId, vendorId).then(res => res.data.data)
	}
}
